<template>
  <tr>
    <td v-if="hidden.id">
      <fa
        v-if="canDestroy"
        icon="trash"
        class="text-danger pointer"
        @click="destroyOrder(item.id)">
      </fa>
      {{ item.id }}
    </td>
    <td v-if="hidden.name">
      <div v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.approvedAt, 'text-warning': !ledger.approvedAt}">
          {{ledger.account.code}}: {{ledger.account.name}}
        </small>
      </div>
      <span style="white-space: pre-line;">
        <span v-if="['invoice', 'reduceDebt', 'badDebt', 'debitNote'].includes(item.type)">
          <router-link
            :to="toDoc(item.parent.doc)"
            class="text-decoration-none">
            {{item.parent.doc.code}}
          </router-link>
        </span>
        <span v-else>
          {{ item.name }}
        </span>
      </span>
    </td>
    <td v-if="hidden.parentId">
      <div v-for="ledger in item.ledgers" :key="ledger.id">
        <br>
      </div>
      <span v-if="['invoice'].includes(item.type)">
        <span v-if="item.parent.doc.type === 'iv'">
          <span :class="{
            'text-warning': item.parent.doc.approvedAt && !item.parent.doc.closedAt,
            'text-success': item.parent.doc.closedAt
          }">
            {{ invoiceAt }}
          </span> |
          <span class="text-danger">{{ dueAt }}</span>
        </span>
      </span>
    </td>
    <td v-if="hidden.totalPrice">
      <div v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
      </div>
      {{ item.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import {
  DESTROY_ORDER
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    canDestroy: {
      type: Boolean
    }
  },
  computed: {
    item () {
      return this.row.item
    },
    hidden () {
      return this.row.hidden
    },
    invoiceAt () {
      const invDate = this.item.parent.doc.closedAt || this.item.parent.doc.approvedAt
      return this.$date.format(invDate).display
    },
    dueAt () {
      const invDate = this.item.parent.doc.closedAt || this.item.parent.doc.approvedAt
      const salesCredit = Math.max(this.item.parent.parent.doc.salesCredit, 0)
      return this.$date.format(invDate, 0, salesCredit).display
    }
  },
  methods: {
    destroyOrder (orderId) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId
        }
      })
      .then(() => {
        this.emitOrderUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
