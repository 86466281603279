<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-date
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        label="วันที่ลงในเช็ค (ถ้ามี)"
        v-model="formData.billedAt">
      </sgv-input-date>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="หมายเหตุ"
        v-model="formData.remark">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <DetailFormContactInput
        label="ลูกหนี้"
        placeholder="..."
        :disabled="method !== 'add'"
        class="col-12 col-sm-12"
        select="id"
        @change="setContact"
        v-model="formData.contactId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: v.formData.contactId.$dirty && v.formData.contactId.$error}
        ]">
      </DetailFormContactInput>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import DetailFormContactInput from './DetailFormContactInput'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contact: null
    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    setContact (value) {
      this.contact = value
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    contact (v) {
      this.$emit('contact', v)
    }
  },
  components: {
    DetailFormContactInput,
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
