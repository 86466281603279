<template>
  <div class="mt-3">
    <sgv-table
      :items="orders"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :toolbars="toolbars">

      <template slot="option" v-if="options.toolbar === 'other'">
        <div class="mb-3">
          <DetailOrderExpenseInput
            ref="orderExpense"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId"
            @updated="orderList">
          </DetailOrderExpenseInput>

          <DetailOrderWithholdingPriceInput
            ref="orderWithholdingPrice"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId"
            @updated="orderList">
          </DetailOrderWithholdingPriceInput>

          <DetailOrderPaymentInput
            ref="orderPayment"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId"
            :orders="orders"
            @updated="orderList">
          </DetailOrderPaymentInput>
        </div>
      </template>

      <template slot="option" v-if="options.toolbar === 'payable'">
        <DetailOrderPayableAvailable
          ref="orderPayableAvailable"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          @updated="orderList">
        </DetailOrderPayableAvailable>
      </template>

      <template slot="option" v-if="options.toolbar === 'invoice'">
        <DetailOrderInvoiceAvailable
          ref="orderInvoiceAvailable"
          class="mb-3"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          @updated="orderList">
        </DetailOrderInvoiceAvailable>
      </template>

      <template slot="option" v-if="options.toolbar === 'reduceDebt'">
        <DetailOrderReduceDebtAvailable
          ref="orderReduceDebtAvailable"
          class="mb-3"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          @updated="orderList">
        </DetailOrderReduceDebtAvailable>
      </template>

      <template slot="option" v-if="options.toolbar === 'badDebt'">
        <DetailOrderBadDebtAvailable
          ref="orderBadDebtAvailable"
          class="mb-3"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          @updated="orderList">
        </DetailOrderBadDebtAvailable>
      </template>

      <template slot="option" v-if="options.toolbar === 'debitNote'">
        <DetailOrderDebitNoteAvailable
          ref="orderDebitNoteAvailable"
          class="mb-3"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          @updated="orderList">
        </DetailOrderDebitNoteAvailable>
      </template>

      <template slot="option" v-if="options.toolbar === 'deposit'">
        <DetailOrderDepositAvailable
          ref="orderDepositAvailable"
          class="mb-3"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          @updated="orderList">
        </DetailOrderDepositAvailable>
      </template>

      <template slot-scope="row">
        <DetailOrderSubItem
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :row="row"
          :canDestroy="canDestroy"
          @updated="orderDeleted">
        </DetailOrderSubItem>
      </template>

      <div class="" slot="action">
        <sgv-input-text
          class="my-md-0"
          disabled
          :value="totalPayment">
        </sgv-input-text>
      </div>
    </sgv-table>
  </div>
</template>

<script>
import round from 'lodash/round'
import DetailOrderExpenseInput from './DetailOrderExpenseInput'
import DetailOrderWithholdingPriceInput from './DetailOrderWithholdingPriceInput'
import DetailOrderPaymentInput from './DetailOrderPaymentInput'
import DetailOrderPayableAvailable from './DetailOrderPayableAvailable'
import DetailOrderInvoiceAvailable from './DetailOrderInvoiceAvailable'
import DetailOrderReduceDebtAvailable from './DetailOrderReduceDebtAvailable'
import DetailOrderBadDebtAvailable from './DetailOrderBadDebtAvailable'
import DetailOrderDebitNoteAvailable from './DetailOrderDebitNoteAvailable'
import DetailOrderDepositAvailable from './DetailOrderDepositAvailable'
import DetailOrderSubItem from './DetailOrderSubItem.vue'
import {
  LIST_ORDER,
  WATCH_DOC_UPDATED,
  LIST_ORDER_REDUCE_DEBT,
  LIST_ORDER_BAD_DEBT,
  LIST_ORDER_DEBIT_NOTE,
  LIST_ORDER_DEPOSIT
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      orders: [],
      reduceDebts: [],
      badDebts: [],
      debitNotes: [],
      deposits: [],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'parentId', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      }
    }
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'วันที่จัดส่ง | ครบกำหนด', value: 'parentId'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      const arr = [
        { value: 'other', icon: 'plus', class: 'text-success'},
        { value: 'payable', icon: 'file-invoice', class: 'text-info' },
        { value: 'invoice', icon: 'truck', class: 'text-warning' }
      ]

      if (this.reduceDebts.length > 0) {
        arr.push({value: 'reduceDebt', icon: 'file-download', class: 'text-success'})
      }

      if (this.debitNotes.length > 0) {
        arr.push({value: 'debitNote', icon: 'dollar-sign', class: 'text-warning'})
      }

      if (this.badDebts.length > 0) {
        arr.push({value: 'badDebt', icon: 'dollar-sign', class: 'text-danger'})
      }

      if (this.deposits.length > 0) {
        arr.push({ value: 'deposit', icon: 'dollar-sign', class: 'text-success' })
      }

      return arr
    },
    ledgersSum () {
      return this.orders.reduce((t,v) => {
        const x = t + v.ledgers.reduce((sub, ledger) => {
          return sub + Number(ledger.amount)
        }, 0)
        return round(x, 2)
      }, 0)
    },
    totalPayment () {
      const total =
      this.orders
      .filter(v => ['changeError', 'payment']
      .includes(v.type))
      .reduce((t,v) => {
        t += +v.totalPrice
        return round(t, 2)
      }, 0)
      const paid =
      this.orders
      .filter(v => v.type === 'payment')
      .reduce((t,v) => {
        t += +v.totalPrice
        return round(t, 2)
      }, 0)

      if (this.formData.closedAt) {
        return `ชำระจริง: ${paid} | ยอดรวม: ${total}`
      } else {
        return `ยอดรวม: ${total}`
      }
    },
    canDestroy () {
      const types = ['invoice', 'badDebt', 'reduceDebt', 'other', 'debitNote']
      return !this.formData.closedAt && types.includes(this.options.toolbar)
    }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.orderList()
        }
      },
    },
  },
  methods: {
    reduceDebtList () {
      this.$apollo.query({
        query: LIST_ORDER_REDUCE_DEBT(this.templateType),
        variables: {
          docType: this.docType,
          contactId: this.formData.contactId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.reduceDebts = res.data.orders
      })
      .catch(() => {
        this.reduceDebts = []
      })
    },
    badDebtList () {
      this.$apollo.query({
        query: LIST_ORDER_BAD_DEBT(this.templateType),
        variables: {
          docType: this.docType,
          contactId: this.formData.contactId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.badDebts = res.data.orders
      })
      .catch(() => {
        this.badDebts = []
      })
    },
    debitNoteList () {
      this.$apollo.query({
        query: LIST_ORDER_DEBIT_NOTE(this.templateType),
        variables: {
          docType: this.docType,
          contactId: this.formData.contactId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.debitNotes = res.data.orders
      })
      .catch(() => {
        this.debitNotes = []
      })
    },
    depositList() {
      this.$apollo.query({
        query: LIST_ORDER_DEPOSIT(this.templateType),
        variables: {
          docType: this.docType,
          contactId: this.formData.contactId
        },
        fetchPolicy: 'no-cache'
      })
        .then(res => {
          this.deposits = res.data.orders
        })
        .catch(() => {
          this.deposits = []
        })
    },
    orderList () {
      this.$apollo.query({
        query: LIST_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        const orders = res.data.orders
        const items = orders.filter(v => v.type === 'item')
        const invoices = orders.filter(v => v.type === 'invoice')
        const badDebts = orders.filter(v => v.type === 'badDebt')
        const debitNotes = orders.filter(v => v.type === 'debitNote')
        const reduceDebts = orders.filter(v => v.type === 'reduceDebt')
        const depositPayments = orders.filter(v => v.type === 'depositPayment')
        const expenses = orders.filter(v => v.type === 'expense')
        const withholdings = orders.filter(v => v.type === 'withholdingPrice')
        const payments = orders.filter(v => v.type === 'payment')
        const netPrices = orders.filter(v => v.type === 'netPrice')
        const changeErrors = orders.filter(v => v.type === 'changeError')
        this.orders = [
          ...items,
          ...invoices,
          ...badDebts,
          ...debitNotes,
          ...reduceDebts,
          ...expenses,
          ...withholdings,
          ...depositPayments,
          ...payments,
          ...changeErrors,
          ...netPrices,
        ]
      })
      .catch(() => {
        this.orders = []
      })
    },
    orderDeleted () {
      this.orderList()

      if (this.options.toolbar === 'invoice') {
        this.$refs.orderInvoiceAvailable.refetchList()
      } if (this.options.toolbar === 'payable') {
        this.$refs.orderPayableAvailable.refetchList()
      } else if (this.options.toolbar === 'reduceDebt') {
        this.$refs.orderReduceDebtAvailable.refetchList()
      } else if (this.options.toolbar === 'badDebt') {
        this.$refs.orderBadDebtAvailable.refetchList()
      } else if (this.options.toolbar === 'debitNote') {
        this.$refs.orderDebitNoteAvailable.refetchList()
      } else if (this.options.toolbar === 'deposit') {
        this.$refs.orderDepositAvailable.refetchList()
      }
    }
  },
  watch: {
    'formData.approvedAt': {
      handler () {
        this.options.toolbar = null
      }
    }
  },
  created () {
    this.orderList()
    this.reduceDebtList()
    this.badDebtList()
    this.debitNoteList()
    this.depositList()
  },
  components: {
    DetailOrderExpenseInput,
    DetailOrderWithholdingPriceInput,
    DetailOrderPaymentInput,
    DetailOrderPayableAvailable,
    DetailOrderInvoiceAvailable,
    DetailOrderReduceDebtAvailable,
    DetailOrderBadDebtAvailable,
    DetailOrderDebitNoteAvailable,
    DetailOrderDepositAvailable,
    DetailOrderSubItem
  }
}
</script>

<style lang="css" scoped>
</style>
