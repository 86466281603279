<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <span
          :class="{
            'text-warning': item.invoice.approvedBy && !item.invoice.closedBy,
            'text-success': item.invoice.closedBy
          }">
          {{item.invoice.code}}
        </span>
        <span class="float-right">{{item.salesorder.code}}</span>
      </div>
      <div class="card-text">
        <span class="text-primary">
          {{item.dueAt | date}}
        </span>

        <span class="float-right">
          <span class="text-danger">
            {{item.remaining | comma(-1)}}
          </span> /
          <span class="text-success">
            {{item.totalPrice | comma(-1)}}
          </span>
          บาท
        </span>
      </div>

      <div class="row mb-0">
        <div class="col-12">
          <div class="form-row mt-3">
            <sgv-input-currency
              class="col-10 mb-0"
              v-model="totalPrice">
            </sgv-input-currency>

            <button
              class="btn btn-success form-group col mb-0"
              @click="addOrder">
              <fa icon="plus"></fa>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { CREATE_ORDER_INVOICE } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      totalPrice: this.item.remaining
    }
  },
  methods: {
    addOrder () {
      this.$apollo.mutate({
        mutation: CREATE_ORDER_INVOICE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          orderId: this.item.id,
          totalPrice: this.totalPrice
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    },
  },
  watch: {
    'item.remaining': {
      handler (v) {
        this.totalPrice = v
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
