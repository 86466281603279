<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        class="col-12"
        v-model="search" label="ค้นหา">
      </sgv-input-text>
    </div>

    <div class="row">
      <div
        class="col-12 col-md-6 my-2"
        v-for="item in items"
        :key="item.id">
        <DetailOrderReduceDebtAvailableCard
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :item="item"
          @updated="emitUpdated">
        </DetailOrderReduceDebtAvailableCard>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { LIST_ORDER_REDUCE_DEBT } from './graph'
import DetailOrderReduceDebtAvailableCard from './DetailOrderReduceDebtAvailableCard.vue'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_ORDER_REDUCE_DEBT(this.templateType),
        variables: {
          docType: this.docType,
          contactId: this.contactId,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.orders
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    refetchList () {
      if (this.search) {
        this.getList({
          limit: 10,
          params: {
            search: this.search
          }
        })
      } else {
        this.getList({
          limit: 10
        })
      }
    },
    emitUpdated () {
      this.refetchList()
      this.$emit('updated', null)
    }
  },
  watch: {
    search: {
      handler () {
        this.refetchList()
      },
      immediate: true
    }
  },
  components: {
    DetailOrderReduceDebtAvailableCard
  }
}
</script>

<style lang="css" scoped>
</style>
